
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































































































































































































































































































































































.eligibility-results,
[class*='eligibility-results--'] {
  position: relative;
}

.eligibility-results__specs,
.eligibility-results__content,
.eligibility-results__cta {
  margin-bottom: $spacing * 2.5;
}

.eligibility__icon {
  fill: $c-pink-medium;
}

.eligibility__title {
  margin-top: 1rem;
}

.eligibility__message {
  margin-bottom: 3rem;
  font-size: 2.4rem;
}

.eligibility__success {
  .eligibility__cta:nth-child(even) {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $c-gray;

    .btn {
      color: $c-blue-light;
      background: none;
      border: 1px solid $c-blue-light;

      ::v-deep {
        .btn__icon svg {
          fill: $c-blue-light;
        }

        .btn__link__title {
          color: $c-blue-light;
        }
      }
    }
  }
}

.eligibility__back {
  margin-top: 2rem;
}

.eligibility-results__banner {
  margin-top: $spacing * 1.5;
}

::v-deep .wysiwyg {
  width: 100%;
}

::v-deep .promo-banner__promo {
  order: 1;
  margin-left: auto;
}

pre {
  padding: 0.5rem;
  border: 1px solid $c-pink-lightest;
}

.eligibility-results__gigasale__action {
  margin-top: $spacing;

  & + & {
    margin-left: $spacing;
  }
}
